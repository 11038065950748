import { Env } from 'typings';

export const environment: Partial<Env> = {
  production: true,
  domain: ".migo-thermostat.com",
  invitationUrl: "https://authbeta.migo-thermostat.com/invitation/home/",
  authUrl: "https://authbeta.migo-thermostat.com/access/login/",
  apiUrl: "https://app.netatmo.net/api/",
  syncApiUrl: "https://app.netatmo.net/syncapi/v1/",
  cookiePrefix: "betamigothermostatcom",
  langsUrl: "/langs/",
  helpcenterUrl: "https://helpcenterbeta.netatmo.com/hc/",
  appName: "app_thermostat_migo",
  myAccountUrl: "https://myconnectedaccountbeta.migo-thermostat.com/",
  faqUrl: "https://settingsbeta.migo-thermostat.com/#/faq",
  app: "Migo",
};
